// @PREXIs
export const PREFIX_DASHBOARD = '/dashboard';
export const PREFIX_ADMIN = '/administracion';

// @NAVIGATION
export const NAVIGATION = {
  HOME: `${PREFIX_DASHBOARD}/fundacites`,
  LOGIN: `/iniciar-sesion`,
  INSTITUCIONES: `${PREFIX_DASHBOARD}/instituciones`,
  USUARIOS: `${PREFIX_DASHBOARD}/usuarios`,
  ACTIVIDADES: `${PREFIX_DASHBOARD}/actividades-ludicas`,

  SITIOS: `${PREFIX_DASHBOARD}/sitios`,

  RUTAS: `${PREFIX_DASHBOARD}/rutas`,
  RECUPERAR: 'recuperar',
  RESETEAR: 'resetear/:resetToken',
  // REGISTER
  REGISTER: '/registrar',
  PERFIL: `${PREFIX_DASHBOARD}/perfil`
};
export default NAVIGATION;
