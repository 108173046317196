import { request } from 'lib/request';

// get all Activities
export function getAllSitesServices({ params, authRequire }: any) {
  const { skip, take } = params as any;
  return request('/sites', {
    method: 'get',
    authRequire,
    params: { skip, take, ...params }
  });
}

export function getTypeSites({ authRequire }: any) {
  return request('/type-sites', {
    method: 'get',
    authRequire
  });
}

//create Activities
export function createSitesServices({ payload, authRequire }: any) {
  return request('/sites', {
    method: 'post',
    authRequire,
    data: payload
  });
}

export function updateSitesServices({ payload, authRequire, params }: any) {
  return request(`/sites/${params}`, {
    method: 'put',
    authRequire,
    data: payload
  });
}

export function deleteSitesServices({ authRequire, params }: any) {
  console.log(params);
  return request(`/sites/${params}`, {
    method: 'put',
    authRequire,
    data: { deleted: true }
  });
}
