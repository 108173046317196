import { request } from 'lib/request';

// 
export function sendEmailServices({ data, authRequire }: any) {

    console.log("aqui", data)
  return request('/recover-password', {
    method: 'post',
    authRequire,
    data: data
  });
}

//
export function resetPasswordServices({ data, authRequire }: any) {

  return request('/reset-password', {
    method: 'post',
    authRequire,
    data: data
  });
}