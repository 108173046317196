
import React, { useMemo, useState, useEffect } from 'react';
import styles from 'styles/Layout.module.scss';
import { Typography } from '@mui/material';
import {
  ActionsButtonsTable,
  CustomButton,
  CardNormal,
  Table,
  CustomModal,
  FormSites,
  DialogConfirm
} from 'components/index';
import {
  GridCellParams,
  GridColDef,
  GridValidRowModel
} from '@mui/x-data-grid';
import {
  TGenericResponse,
  TGenericResponseError,
  TOpenModals,
  TPageState
} from 'types/index';
import {
  getAllSitesServices,
  createSitesServices,
  updateSitesServices,
  deleteSitesServices,
} from 'services/sites';
import { searchItemLocal } from 'utils/helpers';


const tiposInstitucion = [
  {
    id: '0',
    name: 'Pública'
  },
  {
    id: '1',
    name: 'Privada'
  },
  {
    id: '2',
    name: 'Semi-Privada'
  }
];

const Usuarios = () => {
  const [openModal, setOpenModal] = useState<TOpenModals>({
    modalCreate: false,
    dialogDelete: false
  });
  const dataUser = searchItemLocal('dataUser')

/* inicializar los datos para el formulario */
const initialDatarow = {
  id: '',
  name: '',
  typeId:'',
  typeSite: undefined,
  municipioId:'',
  activities:'',
  estadoId: dataUser.userRole.ente.estadoId,
  parroquiaId: '',
  phone: '',
  active: undefined,
  biosecurity: undefined,
  bathrooms: undefined,
  parking: undefined,
  medicalService: undefined,
  diningRoom: undefined,

};
  const [loadingService, setLoadingService] = useState<boolean>(false);

  const [rowData, setRowData] = useState({
    data: initialDatarow,
    isNewRecord: true
  });

  const [pageState, setPageState] = React.useState<TPageState>({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 25
  });
  
  const getSites = async () => {
    try {
      setPageState((prev: TPageState) => ({ ...prev, isLoading: true }));
      const { data } = await getAllSitesServices({
        params: {
          skip: pageState?.page * pageState?.pageSize,
          take: pageState?.pageSize,
          deleted: false,
          estadoId:dataUser.userRole.roleId === 1 ? null: dataUser.userRole.ente.estadoId
        },
        authRequire: true,
      });



      setPageState((prev: TPageState) => ({
        ...prev,
        isLoading: false,
        data: data?.data,
        total: data?.total,
      }));
    } catch (err: any) {
      console.log(err)
    }
  };

  const handleCreate = () => {
    setOpenModal({ modalCreate: true });
    setRowData({ data: initialDatarow, isNewRecord: true });
  };
  const handleEditRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ modalCreate: true });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };

  const handleDeleteRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ dialogDelete: true });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };

  const onSubmit = async (data: unknown) => {

    let aux = {...data as any}

    delete aux.estado;
    delete aux.municipio;
    
    aux = {...aux, typeSite: Math.round(aux.typeSite) }

    const payload = aux;
    try {
      
      setLoadingService(true);
      const id = payload.id;
      delete payload.id;
      if (rowData.isNewRecord) {
        await createSitesServices({ payload: payload, authRequire: true });

      } else {
        await updateSitesServices({ payload: payload, params: id, authRequire: true });

      }
    } catch (err) {
      // const message = (err as TGenericResponseError).error as string;
      console.log(err)
    } finally {
      setLoadingService(false);
      getSites();
      setOpenModal({ modalCreate: false });
    }
  };

  const onDeleteRow = async (id: unknown) => {
    try {
      setLoadingService(true);
      (await deleteSitesServices({
        params: id as number,
        authRequire: true
      })) as TGenericResponse;

    } catch (err) {
      console.log(err)
    } finally {
      setLoadingService(false);
      getSites();
      setOpenModal({ dialogDelete: false });
    }
  };

  useEffect(() => {
    getSites()
  }, [])

  //id correp nombre lastname ente cedula rol

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 20 },
      { field: 'name', headerName: 'Nombre', width: 180 },
      { field: 'typeId', headerName: 'Tipo de centro', width: 150, renderCell: params => { return params.row.type.name ? params.row.type.name:'' } },
      { field: 'typeSite', headerName: 'Tipo de sitio', width: 150, renderCell: params => { return  tiposInstitucion[params.row.typeId].name } },
      { field: 'estadoId ', headerName: 'Estado', width: 200,renderCell: params => { return params.row.estado.nombre ? params.row.estado.nombre:'' }  },
      { field: 'municipioId', headerName: 'Municipio', width: 200, renderCell: params => { return params.row.municipio.nombre ? params.row.municipio.nombre:'' }  },
      { field: 'parroquiaId', headerName: 'Parroquia', width: 200,
      renderCell: params => { return params.row.parroquia.nombre ? params.row.parroquia.nombre:'' } },
      { field: 'phone', headerName: 'Teléfono', width: 150 },
      { field: 'active', headerName: 'Disponibilidad', width: 120,
      renderCell: params => { return params.row.active ? 'Si':'No' }},

      { field: 'biosecurity', headerName: 'Bioseguridad', width: 120,
      renderCell: params => { return params.row.biosecurity ? 'Si':'No' }},

      { field: 'bathrooms', headerName: 'Baños', width: 90,
      renderCell: params => { return params.row.bathrooms ? 'Si':'No' }},

      { field: 'parking', headerName: 'Estacionamiento', width: 120,
      renderCell: params => { return params.row.parking ? 'Si':'No' }},

      { field: 'medicalService', headerName: 'Servicio Médico', width: 150,
      renderCell: params => { return params.row.medicalService ? 'Si':'No' }},
      
      { field: 'diningRoom', headerName: 'Comedor', width: 90,
      renderCell: params => { return params.row.diningRoom ? 'Si':'No' }},

      /* {
        field: 'description',
        headerName: 'Descripción',
        width: 330,
        flex: 1
      }, */
      {
        field: 'actions',
        headerName: 'Acciones',
        renderCell: params =>
          <ActionsButtonsTable
            handleEditRow={handleEditRow(params)}
            handleDeleteRow={handleDeleteRow(params)}
          />,
        sortable: false,
        width: 100,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true
      }
    ],
    []
  );

  return (
    <section>
      <div className={styles['modules__header']}>
        <Typography className={styles['page-title']} component="h1">
          Sitios
        </Typography>
        <CustomButton
          typeVariant="contained"
          label="Agregar Nuevo"
          onClick={handleCreate}
          className={styles['button-header']}
        />
      </div>
      <CardNormal>
        <Table
          pageState={pageState}
          setPageState={setPageState}
          columns={columns}
        />
      </CardNormal>

      <CustomModal
        modalTitle={
          rowData.isNewRecord ? 'Crear Sitio' : 'Actualizar Sitio'
        }
        openModal={openModal.modalCreate as boolean}
        setOpenModal={setOpenModal}>
        <FormSites
          onSubmit={onSubmit}
          rowData={rowData}
          loadingService={loadingService}
        />
      </CustomModal>

      <DialogConfirm
        onDelete={onDeleteRow}
        title="Eliminar Usuario"
        openModal={openModal.dialogDelete}
        setOpenModal={setOpenModal}
        idRow={rowData.data.id}>
        ¿Estas seguro de eliminar este Responsable?
      </DialogConfirm>
    </section>
  );
};

export default Usuarios;
