import { request } from 'lib/request';

// get all Activities
export function getAllAcademicRoutesServices({ params, authRequire }: any) {
  const { skip, take } = params as any;
  return request('/routes-planned', {
    method: 'get',
    authRequire,
    params: { skip, take, ...params }
  });
}

//create Activities
export function createAcademicRoutesServices({ payload, authRequire }: any) {
  console.log(payload)


  return request('/routes-planned', {
    method: 'post',
    authRequire,
    data: payload
  });
}

export function updateAcademicRoutesServices({
  payload,
  authRequire,
  params
}: any) {
  return request(`/routes-planned/${params}`, {
    method: 'put',
    authRequire,
    data: payload
  });
}

export function deleteAcademicRoutesServices({ authRequire, params }: any) {
  return request(`/routes-planned/${params}`, {
    method: 'delete',
    authRequire,
    data: { deleted: true }
  });
}
