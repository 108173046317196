import { Outlet } from 'react-router-dom';
import styles from './Login.module.scss';
import { FunctionComponent, PropsWithChildren } from 'react';

const PublicLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  function selectImage() {
    switch (getRandomInt(3)) {
      case 0:
        return <div className={styles.imagePosition}>
          <img src="nina-que-piensa-tiro-estudio.png" alt="nina-que-piensa-tiro-estudio" />
        </div>
      case 1:
        return <div className={styles.imagePosition}>
          <img src="retrato-cintura-arriba-nino-concentrado-camisa-rayas-anteojos-haciendo-boceto.png" alt="nina-que-piensa-tiro-estudio" />
        </div>
      case 2:
        return <div className={styles.imagePosition}>
          <img src="retrato-nina-adolescente-llevar-gafas-sol-tenencia-ella-mochila.png" alt="nina-que-piensa-tiro-estudio" />
        </div>
    }
  }



  return (
    <div className={styles.container}>
      <div className={styles.leftWrapper}>
        <div className={styles.logo}>
          <img className="image" src="logo_semillero_1.svg" alt="" />
        </div>
        {children ? children : <Outlet />}



      </div>
      <div className={styles.rightWrapper}>
        <div className={styles.imagePosition}>
          {/* selectImage() */}
        </div>
      </div>
    </div>
  );
};

/* <div className={styles.background}>
      <div className={styles.centerWrapper}>
        <div className="">
          <div className={styles.logo}>
            <img src="/logo_semillero_1.svg" alt="logo" />
          </div>
          {children ? children : <Outlet />}
        </div>
      </div>
    </div> */

export default PublicLayout;
