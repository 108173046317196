import { request } from 'lib/request';

// get all Activities
export function getAllInstitutions({ params, authRequire }: any) {
  const { skip, take } = params as any;
  return request('/schools', {
    method: 'get',
    authRequire,
    params: { skip, take, ...params }
  });
}

//create Institutions
export function createInstitutions({ payload, authRequire }: any) {
  return request('/schools', {
    method: 'post',
    authRequire,
    data: payload
  });
}

export function updateInstitutions({ payload, authRequire, params }: any) {
  return request(`/schools/${params}`, {
    method: 'put',
    authRequire,
    data: payload
  });
}

export function deleteInstitutions({ authRequire, params }: any) {
  return request(`/schools/${params}`, {
    method: 'put',
    authRequire,
    data: { delete: true }
  });
}
