import React from 'react';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemText, Box } from '@mui/material';
import { searchItemLocal, removeItemLocal } from 'utils/helpers';
import { BiBuildings, BiBus, BiLogOut } from 'react-icons/bi'
import { FaRegLightbulb, FaMapMarkerAlt } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { BsHouseDoor } from 'react-icons/bs'
import { localToken } from 'constants/index';
import { useNavigate } from 'react-router-dom';
import NAVIGATION from 'routes/navigation';
/* import styles from 'styles/ListMenu.module.scss'; */
import styles from './ListMenu.module.scss'

const dataUser = searchItemLocal('dataUser');
const ListMenu: React.FC<{}> = () => {
  const navigate = useNavigate();


  const logOut = () => {
    removeItemLocal(localToken);
    removeItemLocal('dataUser');
    navigate(NAVIGATION.LOGIN);
  };

  return (
    <div /* className={styles['lmao']} */>
      <Box
        component="img"
        sx={{
          margin: "auto auto 30px auto",
          width: "80%",
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
        }}
        alt="semillero logo"
        src="/logo_semillero_1.svg"
      />
      <ListItem key='1' disablePadding>
        <ListItemButton sx={{
          backgroundColor: "#1565c0",
          margin: "1px 0px",
          padding: "0px 8px",
          "&:hover": {
            backgroundColor: "#2196f3"
          }
        }}  >
          <NavLink style={{ color: '#fff', width: '100%', display: 'flex', alignItems: 'center', gap: "2px" }} to='/dashboard/fundacites' end>
            <BiBuildings size={22} />
            <ListItemText
              primary='Descripción Usuarios'
              sx={{
                color: '#fff', textTransform: 'capitalize', '& .MuiListItemButton-root:hover': {
                  background: 'orange'
                }
              }}
            />
          </NavLink>
        </ListItemButton>
      </ListItem>
      {dataUser?.userRole?.roleId === 1 ? <ListItem key='2' disablePadding>
        <ListItemButton sx={{
          backgroundColor: "#1565c0",
          margin: "1px 0px",
          padding: "0px 8px",
          "&:hover": {
            backgroundColor: "#2196f3"
          }
        }}  >
          <NavLink style={{ color: '#fff', width: '100%', display: 'flex', alignItems: 'center', gap: "2px" }} to='/dashboard/actividades-ludicas' end>
            <FaRegLightbulb size={22} />
            <ListItemText
              primary='Actividades'
              sx={{ color: '#fff', textTransform: 'capitalize' }}
            />
          </NavLink>
        </ListItemButton>
      </ListItem> : null}
      {dataUser?.userRole?.roleId > 3 ? null : <ListItem key='3' disablePadding>
        <ListItemButton sx={{
          backgroundColor: "#1565c0",
          margin: "1px 0px",
          padding: "0px 8px",
          "&:hover": {
            backgroundColor: "#2196f3"
          }
        }}  >
          <NavLink style={{ color: '#fff', width: '100%', display: 'flex', alignItems: 'center', gap: "2px" }} to='/dashboard/usuarios' end>
            <FiUsers size={22} />
            <ListItemText
              primary='Usuarios'
              sx={{ color: '#fff', textTransform: 'capitalize' }}
            />
          </NavLink>
        </ListItemButton>
      </ListItem>}
      <ListItem key='4' disablePadding>
        <ListItemButton sx={{
          backgroundColor: "#1565c0",
          margin: "1px 0px",
          padding: "0px 8px",
          "&:hover": {
            backgroundColor: "#2196f3"
          }
        }}  >
          <NavLink style={{ color: '#fff', width: '100%', display: 'flex', alignItems: 'center', gap: "2px" }} to='/dashboard/instituciones' end>
            <BsHouseDoor size={22} />
            <ListItemText
              primary='Instituciones Educativas'
              sx={{ color: '#fff', textTransform: 'capitalize' }}
            />
          </NavLink>
        </ListItemButton>
      </ListItem>

      <ListItem key='5' disablePadding>
        <ListItemButton sx={{
          backgroundColor: "#1565c0",
          margin: "1px 0px",
          padding: "0px 8px",
          "&:hover": {
            backgroundColor: "#2196f3"
          }
        }}  >
          <NavLink style={{ color: '#fff', width: '100%', display: 'flex', alignItems: 'center', gap: "2px" }} to='/dashboard/sitios' end>
            <FaMapMarkerAlt size={22} />
            <ListItemText
              primary='Sitios'
              sx={{ color: '#fff', textTransform: 'capitalize' }}
            />
          </NavLink>
        </ListItemButton>
      </ListItem>


      <ListItem key='6' disablePadding>
        <ListItemButton sx={{
          backgroundColor: "#1565c0",
          margin: "1px 0px",
          padding: "0px 8px",
          "&:hover": {
            backgroundColor: "#2196f3"
          }
        }}  >
          <NavLink style={{ color: '#fff', width: '100%', display: 'flex', alignItems: 'center', gap: "2px" }} to='/dashboard/rutas' end>
            <BiBus size={22} />
            <ListItemText
              primary='Plan de visitas'
              sx={{ color: '#fff', textTransform: 'capitalize' }}
            />
          </NavLink>
        </ListItemButton>
      </ListItem>


      <ListItem key='7' disablePadding>
        <ListItemButton
          sx={{
            backgroundColor: "#1565c0",
            margin: "1px 0px",
            padding: "0px 8px",
            "&:hover": {
              backgroundColor: "#2196f3"
            }
          }}
        >
          <div
            onClick={() => logOut()}
            style={{ color: '#fff', width: '100%', display: 'flex', alignItems: 'center', gap: "2px" }}
          >
            <BiLogOut size={22} />
            <ListItemText
              primary='Salir'
              sx={{ color: '#fff', textTransform: 'capitalize' }}
            />
          </div>
        </ListItemButton>
      </ListItem>

    </div>

  );
};


export default ListMenu;
