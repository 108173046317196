import { TRoute } from 'types';
import { typeRouteEnum } from 'lib/enums';
import { NAVIGATION } from './navigation';
import Login from 'pages/login/Login';
import Fundacites from 'pages/fundacites/Fundacites';
import Instituciones from 'pages/instituciones/Instituciones';
import Usuarios from 'pages/usuarios/Usuarios';
import Resetear from 'pages/resetear/Resetear';
import ActividadesLudicas from 'pages/actividades-ludicas/ActividadesLudicas';

import Perfil from 'pages/perfil/Perfil';


import Sitios from 'pages/sitios/Sitios'
import Register from 'pages/registrarse/Register'
import Recuperar from 'pages/recuperar/Recuperar'
import Rutas from 'pages/rutas/rutas';

/*
import Recover from 'pages/recover/Recover';
import Register from 'pages/register/Register'; */

export const routes: TRoute[] = [
  {
    label: 'Principal',
    path: NAVIGATION.HOME,
    component: Fundacites,
    typeRoute: typeRouteEnum.PRIVATE
  },
  {
    label: 'Principal',
    path: NAVIGATION.RECUPERAR,
    component: Recuperar,
    typeRoute: typeRouteEnum.PUBLIC
  },
  {
    label: 'Principal',
    path: NAVIGATION.RESETEAR,
    component: Resetear,
    typeRoute: typeRouteEnum.PUBLIC
  },

  {
    label: 'Instituciones',
    path: NAVIGATION.INSTITUCIONES,
    component: Instituciones,
    typeRoute: typeRouteEnum.PRIVATE
  },
  {
    label: 'Usuarios',
    path: NAVIGATION.USUARIOS,
    component: Usuarios,
    typeRoute: typeRouteEnum.PRIVATE
  },
  {
    label: 'Sitios',
    path: NAVIGATION.SITIOS,
    component: Sitios,
    typeRoute: typeRouteEnum.PRIVATE
  },
  {
    label: 'iniciar-sesion',
    path: NAVIGATION.LOGIN,
    component: Login,
    typeRoute: typeRouteEnum.PUBLIC
  },
  {
    label: 'actividades publicas',
    path: NAVIGATION.ACTIVIDADES,
    component: ActividadesLudicas,
    typeRoute: typeRouteEnum.PRIVATE
  },
  {
    label: 'perfil',
    path: NAVIGATION.PERFIL,
    typeRoute: typeRouteEnum.PRIVATE,
    component: Perfil
  },
  {
    label: 'Rutas',
    path: NAVIGATION.RUTAS,
    component: Rutas,
    typeRoute: typeRouteEnum.PRIVATE
  }
,
  {
    label: 'Registrarse',
    path: NAVIGATION.REGISTER,
    component: Register,
    typeRoute: typeRouteEnum.PUBLIC
  },
  /* {
    label: 'Validando Documento',
    path: NAVIGATION.VALIDATE_DOCUMENT_BY_CODE,
    component: VerifingDocument,
    typeRoute: typeRouteEnum.PUBLIC
  },
  {
    label: 'iniciar-sesion',
    path: NAVIGATION.LOGIN,
    component: Login,
    typeRoute: typeRouteEnum.PUBLIC
  },
  {
    label: 'Recuperar',
    path: NAVIGATION.RECOVER_PASSWORD,
    component: Recover,
    typeRoute: typeRouteEnum.PUBLIC
  },
  {
    label: 'Recuperar',
    path: NAVIGATION.UPDATE_PASSWORD,
    component: UpdatePassword,
    typeRoute: typeRouteEnum.PUBLIC
  } */
];
